<template>
    <div class="align-detail-page">
        <full-loading ref="fullLoading"></full-loading>
        <template v-if="info">
            <page-title :title="info.title||''" :titleEdit="true" @saveTitle="saveEditTitleEvent" style="border: none;" >
                <template slot="info">
                    <div class="more-info">
                        <el-popover trigger="hover">
                            <el-form label-width="80px" size="small" class="more-info-popover">
                                <el-form-item :label="$t('tools.tools_alignTiled_page_form_item_label_1')" v-if="LANGUAGE_DICT">
                                    {{LANGUAGE_DICT[info.sourceLang].shortName}} <i class="el-icon-arrow-right"></i> {{LANGUAGE_DICT[info.targetLang].shortName}}
                                </el-form-item>
                                <!-- <el-form-item label="已对齐条数">{{info.resultCount}} 条</el-form-item> -->
                                <el-form-item :label="$t('tools.tools_alignTiled_page_form_item_label_2')">{{info.createTime}}</el-form-item>
                            </el-form>
                            <svg-icon name="ib-info" class-name="color-555" slot="reference"></svg-icon>
                        </el-popover>
                    </div>
                </template>
                <template slot="center">
                    <div class="result-switch capsule-tabs">
                        <div :class="['item', !info.ai ? 'disabled':'']" @click="switchResultEvent('AI', subtask.status)">
                            <template v-if="!info.ai">
                                <template v-if="subtask.status === 'S'"></template>
                                <template v-else-if="subtask.status === 'F'">
                                    <el-tooltip :content="subtask.failureCause" placement="bottom">
                                        <i class="el-icon-warning-outline" style="color: #F56C6C;"></i>
                                    </el-tooltip>
                                </template>
                                <template v-else><i class="el-icon-loading"></i></template>
                            </template>
                            {{$t('tools.tools_alignTiled_page_tips_1')}}
                        </div>
                        <div class="item active" @click="switchResultEvent('OT', subtask.status)">{{$t('tools.tools_alignTiled_page_tips_2')}}</div>
                    </div>
                    <div class="margin-l-10">
                        <el-tooltip placement="bottom">
                            <div slot="content">{{$t('tools.tools_alignTiled_page_tips_3')}}<br/>{{$t('tools.tools_alignTiled_page_tips_4')}}</div>
                            <svg-icon name="ib-help-o" className="fonts-18 color-555"></svg-icon>
                        </el-tooltip>
                    </div>
                </template>
                
                <template slot="tools">
                    <template v-if="info.allowDownload">
                        <el-button type="primary" round size="small" style="margin-left:10px;" v-permission="`tools:alignment:detailRouter:save`" @click="dialog.show = true" v-if="!info.isSaved"> {{$t('tools.tools_alignTiled_page_btn_1')}}</el-button>
                    </template>
                    <template v-if="info.allowDownload">
                        <el-dropdown  @command="downloadFileEvent">
                            <el-button type="primary" round size="small" :loading="downloadLoading" style="margin-left:10px;">
                                {{$t('tools.tools_alignTiled_page_btn_2')}} <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="word" v-permission="`tools:alignment:detailRouter:downloadWord`">{{$t('tools.tools_alignTiled_page_tips_5')}}</el-dropdown-item>
                                <el-dropdown-item command="excel" v-permission="`tools:alignment:detailRouter:downloadExcel`">{{$t('tools.tools_alignTiled_page_tips_6')}}</el-dropdown-item>
                                <el-dropdown-item command="tmx" v-permission="`tools:alignment:detailRouter:downloadTMX`">{{$t('tools.tools_alignTiled_page_tips_7')}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </template>
            </page-title>
            <div class="page-tools-content" style="margin-bottom:0px;padding:0 0 8px 0;">
                <div class="left">
                    <el-tooltip :content="$t('tools.tools_alignTiled_page_tips_8')" placement="top">
                        <span>
                            <el-button size="small" plain round :loading="repealBtnLoading" @click="repealBtnEvent"><svg-icon name="ib-repeal"></svg-icon> {{$t('tools.tools_alignTiled_page_btn_3')}}</el-button>
                        </span>
                    </el-tooltip>
                    <el-tooltip :content="$t('tools.tools_alignTiled_page_tips_9')" placement="top">
                        <span class="margin-l-10">
                            <el-button size="small" plain round :loading="recoverBtnLoading" @click="recoverBtnEvent"><svg-icon name="ib-recover"></svg-icon> {{$t('tools.tools_alignTiled_page_btn_4')}}</el-button>
                        </span>
                    </el-tooltip>
                    <el-tooltip :content="$t('tools.tools_alignTiled_page_tips_10')" placement="top">
                        <span class="margin-l-10">
                            <el-button size="small" plain round :disabled="addBtnDisabled" @click="addBlankLineEvent" icon="el-icon-plus">{{$t('tools.tools_alignTiled_page_btn_5')}}</el-button>
                        </span>
                    </el-tooltip>
                    <el-tooltip :content="$t('tools.tools_alignTiled_page_tips_11')" placement="top">
                        <span class="margin-l-10">
                            <el-button size="small" plain round :disabled="editBtnDisabled" @mousedown.native.prevent @click="editBtnEvent"><svg-icon name="ib-edit-source"></svg-icon> {{$t('tools.tools_alignTiled_page_btn_6')}}</el-button>
                        </span>
                    </el-tooltip>
                    <el-tooltip :content="$t('tools.tools_alignTiled_page_tips_12')" placement="top">
                        <span class="margin-l-10">
                            <el-button size="small" plain round :disabled="splitBtnDisabled" :loading="splitBtnLoading" @mousedown.native.prevent @click="splitColEvent"><svg-icon name="ib-split"></svg-icon> {{$t('tools.tools_alignTiled_page_btn_7')}}</el-button>
                        </span>
                    </el-tooltip>
                    <el-tooltip :content="$t('tools.tools_alignTiled_page_tips_13')" placement="top">
                        <span class="margin-l-10">
                            <el-button size="small" plain round :disabled="mergeBtnDisabled" :loading="mergeBtnLoading" @click="mergeColEvent"><svg-icon name="ib-merge"></svg-icon> {{$t('tools.tools_alignTiled_page_btn_8')}}</el-button>
                        </span>
                    </el-tooltip>
                    <el-tooltip :content="$t('tools.tools_alignTiled_page_tips_14')" placement="top">
                        <span class="margin-l-10">
                            <el-button size="small" plain round :disabled="deleteBtnDisabled" :loading="deleteBtnLoading" @click="e=>deleteConfirmEvent(e,null)"><svg-icon name="ib-trash-o"></svg-icon> {{$t('tools.tools_alignTiled_page_btn_9')}}</el-button>
                        </span>
                    </el-tooltip>
                
                </div>
                <div class="right">
                    <el-popover trigger="hover" width="237" placement="bottom">
                        <div class="">
                            <el-table :data="hotkeyList" size="small">
                                <el-table-column :label="$t('tools.tools_alignTiled_page_hotkeyListTable_item_label_1')" prop="name" width="90"></el-table-column>
                                <el-table-column :label="$t('tools.tools_alignTiled_page_hotkeyListTable_item_label_2')">
                                    <template slot-scope="scope">
                                        <div v-html="scope.row.key"></div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <el-button slot="reference" size="small" plain round>{{$t('tools.tools_alignTiled_page_btn_10')}}</el-button>
                    </el-popover>
                    <el-tooltip :content="$t('tools.tools_alignTiled_page_tips_15')" placement="top">
                        <span class="margin-x-10">
                            <el-button size="small" plain round @click="findReplaceDialog.show = true"> {{$t('tools.tools_alignTiled_page_btn_11')}}</el-button>
                        </span>
                    </el-tooltip>
                    <el-divider direction="vertical"></el-divider>
                    
                    <div style="width:140px;margin-left:10px;">
                        <el-select v-model="searchForm.sort" size="small" clearable :placeholder="$t('tools.tools_alignTiled_page_select_placeholder_1')" @change="selectSearchEvent">
                            <template v-for="(value, key) in sortOptions">
                                <el-option :key="key" :value="key" :label="value"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div style="margin-left:10px;" v-if="searchForm.sort">
                        <el-button type="primary" size="small" round @click="clearFilterAlignResultBtnEvent">{{$t('tools.tools_alignTiled_page_btn_12')}}</el-button>
                    </div>
                </div>
            </div>
            <el-alert type="info" center  effect="dark" style="min-height: 40px">
                <template slot="title">
                    <span class="fonts-14 margin-l-10 text-weight-400">
                    <svg-icon name="ib-trumpet" class-name="fonts-16 margin-r-10"></svg-icon> {{$t('tools.tools_alignTiled_page_tips_16')}} <svg-icon name="ib-handle" class-name="fonts-18"></svg-icon> <span v-html="$t('tools.tools_alignTiled_page_tips_17')"></span>
                    </span>
                </template>
            </el-alert>
            <div class="flex-table flex flex-column overflow-hidden">
                <div class="table-header">
                    <div class="width-40">{{$t('tools.tools_alignTiled_page_tips_18')}}</div>
                    <div class="flex-1" style="">
                        {{$t('tools.tools_alignTiled_page_tips_19', {lang: info && LANGUAGE_DICT && LANGUAGE_DICT[info.sourceLang] && LANGUAGE_DICT[info.sourceLang].name})}}
                    </div>
                    <div class="flex-1">
                        {{$t('tools.tools_alignTiled_page_tips_20', {lang: info && LANGUAGE_DICT && LANGUAGE_DICT[info.targetLang] && LANGUAGE_DICT[info.targetLang].name})}}
                    </div>
                </div>
                
                <div class="table-body flex-1" style="overflow-y: scroll;" @contextmenu.prevent="onContextmenu">
                    <template v-for="(n,index) in total">
                        <template v-if="index >= startIndex && index < endIndex">
                            <div class="table-row" :key="`row-${index}`">
                                <div :key="`number-${index}`" class="table-col width-40" style="padding:8px 10px;">{{index+1}}</div>
                                <template v-if="sourceList[index]">
                                    <div :key="`source-${index}`" :class="['table-col','flex-1',sourceList[index].highlightType === 'WARN'?'unconfirm-row':'',sourceList[index].edit?'stop-dorp':'',checkedIds.indexOf(`${sourceList[index].resultId}-source`)>-1?'table-col-active':'']" 
                                        :data-index="index" data-type="source" 
                                        @click="event=>handleTableCellClick(sourceList[index], index, 'source', event)"
                                        @dblclick="event=>handleTableCellDblClick(sourceList[index], index, 'source', event)">
                                        <div class="table-col-item" :data-index="index" data-type="source">
                                            <template v-if="sourceList[index].edit">
                                                <div :lang="info.sourceLang" class="flex-1">
                                                    <el-input type="textarea" autosize :placeholder="$t('tools.tools_alignTiled_page_input_placeholder_1')"
                                                        v-model="sourceList[index].text" 
                                                        @blur="e=>saveEditEvent(e,{row:sourceList[index], $index:index}, 'source')" 
                                                        @keydown.native.enter="e=>saveEditEvent(e,{row:sourceList[index], $index:index}, 'source')"
                                                        >
                                                    </el-input>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="flag-icon" @click="setColItemFlag(sourceList[index], index, 'source')">
                                                    <el-tooltip placement="top" :content="sourceList[index].highlightType === 'INFO' ? $t('tools.tools_alignTiled_page_tips_21') : $t('tools.tools_alignTiled_page_tips_22')">
                                                        <svg-icon name="ib-star-on" className="fonts-20 star-on" v-if="sourceList[index].highlightType === 'INFO'"></svg-icon>
                                                        <svg-icon name="ib-star-off" className="fonts-20 star-off" v-else></svg-icon>
                                                    </el-tooltip>
                                                </div>
                                                <div class="move-icon"><svg-icon name="ib-handle"></svg-icon></div>
                                                <div class="content flex-1" :lang="info.sourceLang" :title="$t('tools.tools_alignTiled_page_tips_23')" v-html="findReplaceMode?sourceList[index].html:sourceList[index].text"></div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div :key="`source-${index}`" class="table-col flex-1"></div>
                                </template>
                                <template v-if="targetList[index]">
                                    <div :key="`target-${index}`" :class="['table-col','flex-1',targetList[index].highlightType === 'WARN'?'unconfirm-row':'',targetList[index].targetEdit?'stop-dorp':'',checkedIds.indexOf(`${targetList[index].resultId}-target`)>-1?'table-col-active':'']" 
                                        :data-index="index" data-type="target" 
                                        @click="event=>handleTableCellClick(targetList[index], index, 'target', event)"
                                        @dblclick="event=>handleTableCellDblClick(targetList[index], index,'target',event)">
                                        <div class="table-col-item" :data-index="index" data-type="target">
                                            <template v-if="targetList[index].edit">
                                                <div :lang="info.targetLang" class="flex-1">
                                                    <el-input type="textarea" autosize :placeholder="$t('tools.tools_alignTiled_page_input_placeholder_2')"
                                                        v-model="targetList[index].text" 
                                                        @blur="e=>saveEditEvent(e, {row:targetList[index], $index:index}, 'target')" 
                                                        @keydown.native.enter="e=>saveEditEvent(e,{row:targetList[index], $index:index},'target')" ></el-input>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="flag-icon" @click="setColItemFlag(targetList[index], index, 'target')">
                                                    <el-tooltip placement="top" :content="targetList[index].highlightType === 'INFO' ? $t('tools.tools_alignTiled_page_tips_21') : $t('tools.tools_alignTiled_page_tips_22')">
                                                        <svg-icon name="ib-star-on" className="fonts-20 star-on" v-if="targetList[index].highlightType === 'INFO'"></svg-icon>
                                                        <svg-icon name="ib-star-off" className="fonts-20 star-off" v-else></svg-icon>
                                                    </el-tooltip>
                                                </div>
                                                <div class="move-icon"><svg-icon name="ib-handle"></svg-icon></div>
                                                <div class="content flex-1" :lang="info.targetLang" :title="$t('tools.tools_alignTiled_page_tips_23')" v-html="findReplaceMode?targetList[index].html:targetList[index].text"></div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div :key="`target-${index}`" class="table-col flex-1"></div>
                                </template>
                            </div>
                        </template>
                    </template>
                    <template v-if="total === 0">
                        <div class="table-row flex flex-justify-center" style="line-height:30px;">{{$t('tools.tools_alignTiled_page_tips_24')}}</div>
                    </template>
                </div>
                
                <div class="table-footer" style="padding:10px 0;"> 
                    <div class="pagination-content" style="margin:0;">
                        <el-pagination 
                            background 
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            layout="total, sizes, prev, pager, next, jumper"
                            :current-page.sync="pageNumber"
                            :page-sizes="[50, 100, 200]"
                            :page-size="pageSize"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </template>
        <el-dialog :title="$t('tools.tools_alignTiled_page_dialog_1_title')" :visible.sync="dialog.show" destroy-on-close @open="saveToMemDialogOpenEvent" width="580px" top="20px">
            <div class="text-center margin-b-15 flex flex-align-center flex-justify-center" style="color:#FF9900;">
                <svg-icon name="ib-warning" class-name="margin-r-5"></svg-icon> {{$t('tools.tools_alignTiled_page_dialog_1_tips_1')}}
            </div>
            <el-form label-width="90px" style="padding:0 20px;">
                <el-form-item :label="$t('tools.tools_alignTiled_page_dialog_1_form_item_label_1')">
                    <el-autocomplete 
                        suffix-icon="el-icon-arrow-down"
                        @keypress.native.enter.prevent
                        v-model="dialog.name" :placeholder="$t('tools.tools_alignTiled_page_dialog_1_form_item_placeholder_1')" style="width:100%;"
                        :fetch-suggestions="queryMemList"
                        @select="dialogMemNameSelectEvent"></el-autocomplete>
                </el-form-item>
                <el-form-item :label="$t('tools.tools_alignTiled_page_dialog_1_form_item_label_2')" v-if="userInfo.organizationType === 'ENTERPRISE'">
                    <el-select v-model="dialog.team" :placeholder="$t('tools.tools_alignTiled_page_dialog_1_form_item_placeholder_2')">
                      <template v-for="item in dialog.teamOptions">
                        <el-option :label="item.text" :value="item.value" :key="item.value"></el-option>
                      </template>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center;">
                <el-button round plain size="small" @click="dialog.show = false">{{$t('tools.tools_alignTiled_page_dialog_1_btn_1')}}</el-button>
                <el-button round type="primary" size="small" :loading="dialog.loading" @click="saveToMemEvent">{{$t('tools.tools_alignTiled_page_dialog_1_btn_2')}}</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="$t('tools.tools_alignTiled_page_dialog_2_title')" :visible.sync="addDialogShow" destroy-on-close @close="newSourceValue='';newTargetValue='';" width="580px" top="20px">
            <el-form label-width="100px" style="padding:0 20px;">
                <el-form-item :label="$t('tools.tools_alignTiled_page_dialog_2_form_item_label_1', {lang: info && LANGUAGE_DICT && LANGUAGE_DICT[info.sourceLang] && LANGUAGE_DICT[info.sourceLang].name})">
                    <el-input 
                        type="textarea"
                        :rows="4"
                        @keydown.native.enter.prevent
                        v-model="newSourceValue" :placeholder="$t('tools.tools_alignTiled_page_dialog_2_form_item_placeholder_1')"
                        ></el-input>
                </el-form-item>
                <el-form-item :label="$t('tools.tools_alignTiled_page_dialog_2_form_item_label_2', {lang: info && LANGUAGE_DICT && LANGUAGE_DICT[info.targetLang] && LANGUAGE_DICT[info.targetLang].name})">
                    <el-input 
                        type="textarea"
                        :rows="4"
                        @keydown.native.enter.prevent
                        v-model="newTargetValue" :placeholder="$t('tools.tools_alignTiled_page_dialog_2_form_item_placeholder_2')"
                        ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center;">
                <el-button round plain size="small" @click="addDialogShow = false">{{$t('tools.tools_alignTiled_page_dialog_2_btn_1')}}</el-button>
                <el-button round type="primary" size="small" :loading="addDialogLoading" @click="saveAddEvent">{{$t('tools.tools_alignTiled_page_dialog_2_btn_2')}}</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteConfirmEvent"></alert-dialog>
        <alert-dialog ref="cleanUnAlignDialogDom" 
            :message="cleanUnAlignDialog.message" 
            :type="cleanUnAlignDialog.type" 
            :buttonName="cleanUnAlignDialog.buttonName"
            :buttonType="cleanUnAlignDialog.buttonType"
            @confirmEvent="submitClearFilterAlignResult"></alert-dialog>
        <alert-dialog ref="submitAllDialogDom" 
            :message="submitAllDialog.message" 
            :type="submitAllDialog.type" 
            :buttonName="submitAllDialog.buttonName"
            :buttonType="submitAllDialog.buttonType"
            @confirmEvent="submitAllBtnEvent"></alert-dialog>
        <transition name="quickly-add-term-dilaog" enter-active-class="animated fadeInUp faster" leave-active-class="animated fadeOutUp faster">
            <drag-dialog ref="findReplaceDialogDom" :title="$t('tools.tools_alignTiled_page_dialog_3_title')" :width="400" :right="40" :top="112" @close="closeFindReplaceDialogEvent" v-show="findReplaceDialog.show">
                <div class="find-replace-content">
                    <div class="form-content">
                        <div class="input-item">
                            <div class="input-label"><span>{{$t('tools.tools_alignTiled_page_dialog_3_tips_1')}}</span></div>
                            <div class="input-edit">
                                <el-input :placeholder="$t('tools.tools_alignTiled_page_dialog_3_input_placeholder_1')" 
                                    clearable 
                                    suffix-icon="el-icon-search" 
                                    @keyup.enter.native="nextBtnEvent" 
                                    @input="searchInputChangeEvent" 
                                    v-model="findReplaceDialog.searchInputValue"></el-input>
                            </div>
                        </div>
                        <div class="input-item">
                            <div class="input-label"><span>{{$t('tools.tools_alignTiled_page_dialog_3_tips_2')}}</span></div>
                            <div class="input-edit">
                                <el-input :placeholder="$t('tools.tools_alignTiled_page_dialog_3_input_placeholder_2')" 
                                    clearable 
                                    @keyup.enter.native="nextBtnEvent" 
                                    v-model="findReplaceDialog.replaceInputValue"></el-input>
                            </div>
                        </div>
                        <div class="input-actions">
                            <div class="input-check">
                                <el-checkbox v-model="findReplaceDialog.checked" @change="caseSensitiveChangeEvent">{{$t('tools.tools_alignTiled_page_dialog_3_checkbox_1')}}</el-checkbox>
                                <div class="fonts-12" v-if="this.findReplaceDialog.searchInputValue">{{$t('tools.tools_alignTiled_page_dialog_3_tips_3', {number: findCurrentPageSize.current, size1: findCurrentPageSize.count, size2: findSize})}}</div>
                            </div>
                            <div class="input-btns">
                                <el-button round size="mini" 
                                    @click="prevBtnEvent" 
                                    :disabled="!findReplaceDialog.searchInputValue">{{$t('tools.tools_alignTiled_page_dialog_3_btn_1')}}</el-button>
                                <el-button round size="mini" 
                                    @click="nextBtnEvent" 
                                    :disabled="!findReplaceDialog.searchInputValue">{{$t('tools.tools_alignTiled_page_dialog_3_btn_2')}}</el-button>
                                <el-button type="primary" round size="mini" 
                                    :loading="findReplaceDialog.replaceLoading" 
                                    @click="replaceBtnEvent"
                                    :disabled="!(findReplaceDialog.searchInputValue && findReplaceDialog.replaceInputValue)">{{$t('tools.tools_alignTiled_page_dialog_3_btn_3')}}</el-button>
                                <el-button type="primary" round size="mini" 
                                    :loading="findReplaceDialog.replaceAllLoading" 
                                    @click="replaceAllBtnEvent" 
                                    :disabled="!(findReplaceDialog.searchInputValue && findReplaceDialog.replaceInputValue)">{{$t('tools.tools_alignTiled_page_dialog_3_btn_4')}}</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </drag-dialog>
        </transition>
    </div>
</template>
<script>
import { Base64 } from 'js-base64';
import $ from 'jquery';
import { mapState } from 'vuex';
import Sortable, { MultiDrag, Swap } from "sortablejs";
import { Affix } from 'vue-affix';
import draggable from 'vuedraggable'
import FullLoading from '@/components/FullLoading';
import fileDownload from 'js-file-download';
import config from '@/utils/config';
import tools from '@/utils/tools';
import { toolsApi, memoryApi, commonApi } from '@/utils/api';
import PageTitle from '@/components/PageTitle';
import AlertDialog from '@/components/AlertDialog';
import DragDialog from '@/components/DragDialog';

try{
    Sortable.mount(new MultiDrag(), new Swap());
}catch(e){
    console.error("[ERROR]", e);
}

export default {
    components:{PageTitle, AlertDialog, FullLoading, draggable, Affix, DragDialog},
    data(){
        return {
            hotkeyList:[
                {name: this.$t('tools.tools_alignTiled_page_data_hotkeyList_name_1'), key: '<kbd>Ctrl</kbd> / <kbd>⌘</kbd> + <kbd>Z</kbd>'},
                {name: this.$t('tools.tools_alignTiled_page_data_hotkeyList_name_2'), key: '<kbd>Ctrl</kbd> / <kbd>⌘</kbd> + <kbd>Y</kbd>'},
                {name: this.$t('tools.tools_alignTiled_page_data_hotkeyList_name_3'), key: '<kbd>Ctrl</kbd> / <kbd>⌘</kbd> + <kbd>E</kbd>'},
                {name: this.$t('tools.tools_alignTiled_page_data_hotkeyList_name_4'), key: '<kbd>Ctrl</kbd> / <kbd>⌘</kbd> + <kbd>I</kbd>'},
                {name: this.$t('tools.tools_alignTiled_page_data_hotkeyList_name_5'), key: '<kbd>Ctrl</kbd> / <kbd>⌘</kbd> + <kbd>Enter</kbd>'},
                {name: this.$t('tools.tools_alignTiled_page_data_hotkeyList_name_6'), key: '<kbd>Ctrl</kbd> / <kbd>⌘</kbd> + <kbd>M</kbd>'},
                {name: this.$t('tools.tools_alignTiled_page_data_hotkeyList_name_7'), key: '<kbd>Ctrl</kbd> / <kbd>⌘</kbd> + <kbd>Delete</kbd>'},
                {name: this.$t('tools.tools_alignTiled_page_data_hotkeyList_name_8'), key: '<kbd>Ctrl</kbd> / <kbd>⌘</kbd> + <kbd>F</kbd>'},
                {name: this.$t('tools.tools_alignTiled_page_data_hotkeyList_name_9'), key: '<kbd>Ctrl</kbd> / <kbd>⌘</kbd> + ' + this.$t('tools.tools_alignTiled_page_data_hotkeyList_key_tips_1')},
                {name: this.$t('tools.tools_alignTiled_page_data_hotkeyList_name_10'), key: '<kbd>Shift</kbd> + ' + this.$t('tools.tools_alignTiled_page_data_hotkeyList_key_tips_1')},
            ],
            alertInfo:{
                message: this.$t('tools.tools_alignTiled_page_data_alertInfo_message'),
                type:'confirm', //alert ,confirm
                buttonName: this.$t('tools.tools_alignTiled_page_data_alertInfo_buttonName'),
                buttonType:'danger',
            },
            cleanUnAlignDialog:{
                message:'',
                type:'confirm', //alert ,confirm
                buttonName: this.$t('tools.tools_alignTiled_page_data_cleanUnAlignDialog_buttonName'),
                buttonType:'danger',
            },
            submitAllDialog:{
                message: this.$t('tools.tools_alignTiled_page_data_submitAllDialog_message'),
                type:'confirm', //alert ,confirm
                buttonName: this.$t('tools.tools_alignTiled_page_data_submitAllDialog_buttonName'),
                buttonType:'primary',
            },
            info:null,
            subtask:{},
            querySubtaskTimer: null,
            dialog:{
                show:false,
                loading:false,
                name: '',
                memId: '',
                team: '',
                teamOptions: [],
            },
            findReplaceMode: false,
            findReplaceDialog: {
                show: false,
                searchInputValue: '',
                replaceInputValue: '',
                checked: false,
                replaceLoading: false,
                replaceAllLoading: false,

            },
            searchForm:{
                kw:'',
                sort:'',
            },
            sortOptions:{
                'B': this.$t('tools.tools_alignTiled_page_data_sortOptions_value_1'),
                'R': this.$t('tools.tools_alignTiled_page_data_sortOptions_value_2'),
                'E': this.$t('tools.tools_alignTiled_page_data_sortOptions_value_3'),
            },
            searchTimer: null,
            list:[],
            sourceList: [],
            targetList: [],
            total:0,
            pageSize:50,
            pageNumber:1,
            startIndex: 0,
            endIndex: 50,
            hasNextPage: true,
            hasPreviousPage: false,
            addDialogShow:false,
            addDialogLoading:false,
            newSourceValue:'',
            newTargetValue:'',
            // editSourceValue:'',
            // editTargetValue:'',
            tableSelection: [],
            downloadLoading: false,
            leftList:[],
            rightList:[],
            selectedCol:{},
            checkedIds:[],
            splitBtnLoading:false,
            mergeBtnLoading:false,
            exchangeBtnLoading:false,
            deleteBtnLoading:false,
            alignBtnLoading:false,
            repealBtnLoading:false,
            recoverBtnLoading:false,

            addBtnDisabled:true,
            splitBtnDisabled:true,
            mergeBtnDisabled:true,
            exchangeBtnDisabled:true,
            deleteBtnDisabled:true,
            alignBtnDisabled:true,

            deleteUnAlignBtnLoading:false,
            submitRowAlignBtnLoading:false,
            tableHeight:600,

            checkAllSource: false,
            checkAllTarget: false,
            findCurrentPageSize: {
                count: 0,
                current: 0,
            },
            findSize: 0,
            findPageNumbers: [],

            fixScrollTop: 0, //定位操作区滚动条位置 >0时，数据加载完成后自动跳转

            ctrlKeyDown: false, //记录ctrl键时候按下
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    methods:{
        initPage(loadingFlag, action){
            let self = this;
            if(loadingFlag !==  false){
                this.$refs.fullLoading.setShow();
            }
            let url = `${toolsApi.queryBdaAlignResultList}` +
                        `?taskId=${this.$route.query.id}` +
                        `&pageNo=${this.pageNumber}` +
                        `&pageSize=${this.pageSize}`;
            if(this.searchForm.sort){
                url = url + `&queryType=${this.searchForm.sort}`;
            }
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.info = res.data.task;
                    this.sourceList = res.data.sources.map(item=>{
                        return { ...item, edit: false, }
                    });
                    this.targetList = res.data.targets.map(item=>{
                        return { ...item, edit: false, }
                    });
                    
                    this.total = this.targetList.length > this.sourceList.length ? this.targetList.length : this.sourceList.length;
                    // this.pageNumber = this.info.lastStayPageNo;
                    this.pageSize = this.info.resultPageSize;
                    
                    this.startIndex = this.pageNumber * this.pageSize - this.pageSize;
                    this.endIndex = this.pageNumber * this.pageSize;
                    
                    this.querySubtaskStatus();
                    if(this.info && !this.info.ai){
                        if(!this.querySubtaskTimer){
                            this.querySubtaskStatus();
                            this.querySubtaskTimer = setInterval(()=>{
                                self.querySubtaskStatus();
                            }, 2000);
                        }
                    }

                    //查找替换模式下
                    if (this.findReplaceMode === true){
                        if (action !== 'replaceAll'){
                            this.initFindReplaceData();
                        }
                    }
                }
            }).finally(()=>{
                if(loadingFlag !==  false){
                    this.$refs.fullLoading.setHide();
                }
                setTimeout(()=>{
                    self.initTableDrag();
                },100);
                if(this.fixScrollTop > 0){
                    setTimeout(()=>{
                        $('.table-body').scrollTop(self.fixScrollTop);
                        self.fixScrollTop = 0;
                    },5)
                }
            });
        },
        querySubtaskStatus(){
            let url = `${toolsApi.queryAlignSubtaskStatus}?taskId=${this.$route.query.id}&resultPoint=AI`;
            this.$ajax.get(url).then(res=>{
                if(res.status == 200){
                    this.subtask = res.data.subtask;
                    if(this.subtask.status === 'S' || this.subtask.status === 'F'){
                        clearInterval(this.querySubtaskTimer);
                        this.querySubtaskTimer = null;
                        if(this.subtask.status === 'S'){
                            this.info.ai = true;
                        }
                    }
                }
            });
        },
        selectSearchEvent(){
            this.initPage(false);
        },
        handleCurrentChange(val, type){
            let self = this;
            $('.table-body').scrollTop(0);
            this.pageNumber = val;
            this.startIndex = this.pageNumber * this.pageSize - this.pageSize;
            this.endIndex = this.pageNumber * this.pageSize;
            this.setPageSize();
            // this.initPage(false);
            setTimeout(()=>{
                self.initTableDrag();
                if(self.findReplaceMode){
                    $('.find-word').removeClass('active');
                    if(type === 'prev'){
                        $($('.find-word')[$('.find-word').length-1]).addClass('active');
                    }else{
                        $($('.find-word')[0]).addClass('active');
                    }
                    self.$scrollTo($('.find-word.active')[0], 30, {
                        container:$('.table-body')[0],
                        offset: -100,
                    });
                    self.getCurrentPageFindSize();
                }
            },100);
        },
        handleSizeChange(val){
            let self = this;
            this.fixScrollTop = $('.table-body').scrollTop();
            this.pageSize = val;
            this.startIndex = this.pageNumber * this.pageSize - this.pageSize;
            this.endIndex = this.pageNumber * this.pageSize;
            this.setPageSize();
            setTimeout(()=>{
                self.initTableDrag();
                if(self.findReplaceMode){
                    $('.find-word').removeClass('active');
                    $($('.find-word')[0]).addClass('active');
                    self.$scrollTo($('.find-word.active')[0], 30, {
                        container:$('.table-body')[0],
                        offset: -100,
                    });
                    self.getCurrentPageFindSize();
                }
            },100);
        },
        disableTextareaEnterEvent(e){
            if(e && (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13)){
                e.returnValue = false;
                return false;
            }
        },
        saveEditEvent(e,scope,type){
            if(this.splitBtnLoading){
                return false;
            }
            // 拆分快捷键逻辑
            if(e.ctrlKey || e.metaKey){ 
                this.splitColEvent();
                return false;
            }
            let row = scope.row;
            let index = scope.$index;
            let url = toolsApi.updateBdaAlignResult;
            let postData = {
                taskId:this.$route.query.id,
                text:row.text,
                textType: type,
                resultId:scope.row.resultId,
            }
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_saveEditEvent_message_1'));
                    this.splitBtnDisabled = true;
                    if(type === 'source'){
                        this.sourceList.splice(index, 1, {...this.sourceList[index], edit:false});
                    }else if(type === 'target'){
                        this.targetList.splice(index, 1, {...this.targetList[index], edit:false});
                    }
                }
            });
            if(e && (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13)){
                e.returnValue = false;
                return false;
            }
        },
        saveAddEvent(){
            if(!this.newSourceValue){
                this.$message.error(this.$t('tools.tools_alignTiled_page_method_saveAddEvent_message_1'));
                return;
            }
            if(!this.newTargetValue){
                this.$message.error(this.$t('tools.tools_alignTiled_page_method_saveAddEvent_message_2'));
                return;
            }
            let url = toolsApi.createAlignResult;
            let curr = this.selectedCol[Object.keys(this.selectedCol)[0]];
            let postData={
                taskId:this.$route.query.id,
                prevResultId:curr.scope.row.resultId,
                prevSortNo:curr.scope.row.sortNo,
                sourceText:this.newSourceValue,
                targetText:this.newTargetValue
            }
            this.addDialogLoading = true;
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.addDialogShow = false;
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_saveAddEvent_message_3'));
                    this.newSourceValue = '';
                    this.newTargetValue = '';
                    this.selectedCol = {};
                    this.checkedIds = [];
                    this.initPage(false);
                }
            }).finally(()=>{
                this.addDialogLoading = false;
            })
        },
        // 一键清洗按钮事件
        deleteUnAlignBtnEvent(){
            let url = `${toolsApi.cleanUnAlignResult}?taskId=${this.$route.query.id}`;
            this.deleteUnAlignBtnLoading = true;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_deleteUnAlignBtnEvent_message_1'));
                    this.initPage(false);
                }
            }).finally(()=>{
                this.deleteUnAlignBtnLoading = false;
            })
        },
        cancelBtnEvent(scope){
            this.list.splice(scope.$index, 1, {...this.list[scope.$index], edit:false})
        },
        handleTableCellClick(row, index, type, event){ // 单击选中
            // console.log(event);
            let id = row.resultId;
            let key = `${id}-${type}`;
            let scope = {row, $index: index};
            if (event.ctrlKey||event.metaKey){
                if (this.selectedCol.hasOwnProperty(key)){
                    this.$delete(this.selectedCol,key);
                    let _index = this.checkedIds.indexOf(key);
                    this.checkedIds.splice(_index,1);
                }else{
                    this.$set(this.selectedCol,key,{scope, type});
                    this.checkedIds.push(key);
                }
            }else if(event.shiftKey){
                if(this.checkedIds.length > 0){
                    let selLastKey = this.checkedIds[0];
                    let selLastCol = this.selectedCol[selLastKey];
                    let selLastIndex = selLastCol.scope.$index;
                    let selLastType = selLastCol.type;
                    let sIndex = 0;
                    let sType = '';
                    let eIndex = 0;
                    let eType = '';
                    if(index >= selLastIndex){
                        sIndex = selLastIndex;
                        sType = selLastType;
                        eIndex = index;
                        eType = type;
                    }else{
                        sIndex = index;
                        sType = type;
                        eIndex = selLastIndex;
                        eType = selLastType;
                    }
                    let rstCol = {};
                    let rstIds = [];
                    if(sIndex === eIndex){
                        if (sType !== eType){
                            rstCol[`${id}-source`] = {scope, type: 'source'};
                            rstCol[`${id}-target`] = {scope, type: 'target'};
                            rstIds.push(`${id}-source`);
                            rstIds.push(`${id}-target`);
                        }
                    }else{
                        for(let i = sIndex; i <= eIndex; i++){
                            if(sIndex === i && sType === 'target'){
                                rstCol[`${this.targetList[i].resultId}-target`] = {scope: {row: this.targetList[i], $index: i}, type: 'target'};
                                rstIds.push(`${this.targetList[i].resultId}-target`);
                                continue;
                            }
                            if(eIndex === i && eType === 'source'){
                                rstCol[`${this.sourceList[i].resultId}-source`] = {scope: {row: this.sourceList[i], $index: i}, type: 'source'};
                                rstIds.push(`${this.sourceList[i].resultId}-source`);
                                continue;
                            }
                            if((sType === 'source' && sType === eType) || sType !==eType){
                                rstCol[`${this.sourceList[i].resultId}-source`] = {scope: {row: this.sourceList[i], $index: i}, type: 'source'};
                                rstIds.push(`${this.sourceList[i].resultId}-source`);
                            }
                            if((sType === 'target' && sType === eType) || sType !==eType){
                                rstCol[`${this.targetList[i].resultId}-target`] = {scope: {row: this.targetList[i], $index: i}, type: 'target'};
                                rstIds.push(`${this.targetList[i].resultId}-target`);
                            }
                        }
                    }
                    this.selectedCol = rstCol;
                    this.checkedIds = rstIds;
                }else{
                    this.$set(this.selectedCol, key, {scope, type});
                    this.checkedIds.push(key);
                }
                
            }else{
                this.selectedCol = {};
                this.checkedIds = [];
                this.$set(this.selectedCol,key,{scope, type});
                this.checkedIds.push(key);
            }
            
            /* if(row.highlightType!=='NORMAL'){
                let url = `${toolsApi.cancelAlignHighlight}?resultId=${id}`;
                this.$ajax.get(url).then(res=>{
                    if(res.status === 200){
                        this.list.splice(index, 1, {...this.list[index], highlightType:"NORMAL"});
                    }
                })
            } */
        },
        handleTableCellDblClick(row, index, type, event){
            this.splitBtnDisabled = false;
            let edit = row.edit;
            if (!edit) {
                if(type === 'target'){
                    this.targetList.splice(index, 1, {...this.targetList[index], edit:true});
                }else if(type === 'source'){
                    this.sourceList.splice(index, 1, {...this.sourceList[index], edit:true});
                }
                setTimeout(() => {
                    $('.table-col-item').find('textarea').focus();
                }, 100);
            }
        },
        setColItemFlag(item, index, type){ //'target'
            let url = toolsApi.addMark;
            let highlightType = 'INFO';
            if (item.highlightType === 'INFO'){
                url = toolsApi.removeMark;
                highlightType = null;
            }
            let postData = {
                resultId: item.resultId,
                resultPoint: 'OT',
                textType: type
            };
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200){
                    if(type === 'target'){
                        this.targetList.splice(index, 1, {...this.targetList[index], highlightType: highlightType});
                    }else if(type === 'source'){
                        this.sourceList.splice(index, 1, {...this.sourceList[index], highlightType: highlightType});
                    }
                }
            });
        },
        /**插入按钮事件 */
        addBlankLineEvent(){
            let index = 0;
            let keys = Object.keys(this.selectedCol);
            let colData = this.selectedCol[keys[0]];
            if(keys.length>0){
                index = colData.scope.$index;
            }
            let url = toolsApi.insertBdaAlignResult;
            let postData={
                taskId:this.$route.query.id,
                selectedResultId:colData.scope.row.resultId,
                selectedSortNo:colData.scope.row.sortNo,
                textType: colData.type,
            };
            this.addDialogLoading = true;
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    let result = {...res.data.result, edit: false};
                    if(colData.type === 'source'){
                        this.sourceList.splice(index,0,result);
                    }else if(colData.type === 'target'){
                        this.targetList.splice(index,0,result);
                    }
                    let _sId = `${result.resultId}-${colData.type}`;
                    this.checkedIds = [_sId];
                    this.selectedCol = {[_sId]:{scope: {row:result, $index: index}, type: colData.type}};
                    // console.log('addBlankLineEvent::', this.selectedCol, this.checkedIds);
                    this.clearHighlight();
                }
            }).finally(()=>{
                this.addDialogLoading = false;
            })
        },
        /**撤销按钮事件 */
        repealBtnEvent(){
            this.repealBtnLoading = true;
            let url = toolsApi.executeBdaRepeal;
            this.$ajax.post(url, {taskId: this.$route.query.id}).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_repealBtnEvent_message_1'));
                    this.fixScrollTop = $('.table-body').scrollTop();
                    this.initPage(false);
                }
            }).finally(()=>{
                this.repealBtnLoading = false;
            })
        },
        /**恢复按钮事件 */
        recoverBtnEvent(){
            this.recoverBtnLoading = true;
            let url = toolsApi.executeBdaRecover;
            this.$ajax.post(url, {taskId: this.$route.query.id}).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_recoverBtnEvent_message_1'));
                    this.fixScrollTop = $('.table-body').scrollTop();
                    this.initPage(false);
                }
            }).finally(()=>{
                this.recoverBtnLoading = false;
            })
        },
        /**拆分按钮事件 */
        splitColEvent(){
            let el = $('.table-col-item textarea');
            if(el.length === 0){
                this.$message.error(this.$t('tools.tools_alignTiled_page_method_splitColEvent_message_1'));
                return;
            }
            let index = el.parents('.table-col').data('index');
            let type = el.parents('.table-col').data('type');
            let item = {};
            if(type === 'source'){
                item = this.sourceList[index];
            }else if(type === 'target'){
                item = this.targetList[index];
            }
            let text = item.text;

            let pos = window.getSelection();
            let offset = el[0].selectionStart;
            if(offset === 0 || offset === text.length){
                this.$message.error(this.$t('tools.tools_alignTiled_page_method_splitColEvent_message_2'));
                return;
            }
            let left = text.substring(0,offset);
            let right = text.substring(offset,text.length);
            let url = toolsApi.splitBdaResult;
            let postData={
                taskId: this.$route.query.id,
                resultId: item.resultId,
                textType: type,
                texts:[
                    left,
                    right
                ]
            };
            this.splitBtnLoading = true;

            if(type === 'source'){
                this.sourceList.splice(index, 1, {...this.sourceList[index], edit:false});
            }else if(type === 'target'){
                this.targetList.splice(index, 1, {...this.targetList[index], edit:false});
            }
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_splitColEvent_message_3'));
                    this.fixScrollTop = $('.table-body').scrollTop();
                    this.checkedIds = [`${item.resultId}-${type}`];
                    this.selectedCol = {[`${item.resultId}-${type}`]: {scope: {row: item, $index: index}, type}};
                    this.initPage(false);
                    this.splitBtnDisabled = true;
                    this.clearHighlight();
                }
            }).finally(()=>{
                this.splitBtnLoading = false;
            })
            return false;
        },
        //合并按钮事件
        mergeColEvent(){
            let url = toolsApi.mergeBdaResult;
            let keys = Object.keys(this.selectedCol);
            let tmpList = [];
            let hasSource = false;
            let hasTarget = false;
            keys.map(key => {
                let item = this.selectedCol[key];
                tmpList.push(item.scope.row.resultId);
                if(item.type === 'source'){
                    hasSource = true;
                }
                if(item.type === 'target'){
                    hasTarget = true;
                }
            });
            if(hasSource && hasTarget){
                this.$message.error(this.$t('tools.tools_alignTiled_page_method_mergeColEvent_message_1'));
                return;
            }
            let params = {
                taskId: this.$route.query.id,
                textType: this.selectedCol[keys[0]].type,
                resultIds: tmpList,
            };
            this.mergeBtnLoading = true;
            this.$ajax.post(url,params).then(res=>{
                if(res.status === 200){
                    let firstSelectedCol = this.selectedCol[keys[0]];
                    // console.log('firstSelectedCol:::',firstSelectedCol)
                    let firstSelectedColIndex = firstSelectedCol.scope.$index;
                    this.checkedIds = [`${firstSelectedCol.scope.row.resultId}-${firstSelectedCol.type}`];
                    this.selectedCol = {[`${firstSelectedCol.scope.row.resultId}-${firstSelectedCol.type}`]: firstSelectedCol};
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_mergeColEvent_message_2'));
                    this.fixScrollTop = $('.table-body').scrollTop();
                    this.clearHighlight();
                    this.initPage(false);
                }
            }).finally(()=>{
                this.mergeBtnLoading = false;
            })
        },
        //对调按钮事件
        exchangeColEvent(){
            let url = toolsApi.submitAlignDrag;
            let active = this.selectedCol[Object.keys(this.selectedCol)[0]];
            let passive = this.selectedCol[Object.keys(this.selectedCol)[1]];
            let params = {
                taskId: this.$route.query.id,
                operationType:'exchange',
                active: {
                    resultId: active.scope.row.resultId,
                    textType: active.type,
                    sortNo: active.scope.row.sortNo,
                },
                passive: {
                    resultId: passive.scope.row.resultId,
                    textType: passive.type,
                    sortNo: passive.scope.row.sortNo,
                }
            };
            this.exchangeBtnLoading = true;
            this.$ajax.post(url,params).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_exchangeColEvent_message_1'));
                    this.checkedIds = [];
                    this.selectedCol = {};
                    this.fixScrollTop = $('.table-body').scrollTop();
                    this.clearHighlight();
                    this.initPage(false);
                }
            }).finally(()=>{
                this.exchangeBtnLoading = false;
            })
        },
        //对齐按钮事件
        aligningColEvent(){
            let url=toolsApi.submitAlignPair;
            let keys = Object.keys(this.selectedCol);
            let tmpSources = [];
            let hasEmptySourceText = false;
            keys.map(key => {
                let item = this.selectedCol[key];
                if(item.type === 'source'){
                    tmpSources.push({resultId: item.scope.row.resultId, type: item.type});
                    if(!item.scope.row.sourceText){
                        hasEmptySourceText = true;
                    }
                }
            });
            let tmpTargets = [];
            let hasEmptyTargetText = false;
            keys.map(key => {
                let item = this.selectedCol[key];
                if(item.type === 'target'){
                    tmpTargets.push({resultId: item.scope.row.resultId, type: item.type});
                    if(!item.scope.row.targetText){
                        hasEmptyTargetText = true;
                    }
                }
            });
            if(hasEmptySourceText || hasEmptyTargetText){
                this.$message.error(this.$t('tools.tools_alignTiled_page_method_aligningColEvent_message_1'));
                return;
            }
            let postData={
                taskId: this.$route.query.id,
                sources: tmpSources,
                targets:tmpTargets,
            };
            this.alignBtnLoading = true;
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_aligningColEvent_message_2'));
                    this.selectedCol = {};
                    this.checkedIds = [];
                    this.fixScrollTop = $('.table-body').scrollTop();
                    this.clearHighlight();
                    this.initPage(false);
                }
            }).finally(()=>{
                this.alignBtnLoading = false;
            })
        },
        //行确认按钮事件
        submitRowAlignBtnEvent(item, index){
            if(!item.sourceText || !item.targetText){
                this.$message.error(this.$t('tools.tools_alignTiled_page_method_submitRowAlignBtnEvent_message_1'));
                return;
            }
            this.submitRowAlignBtnLoading = true;
            let url = `${toolsApi.confirmRowAlign}?taskId=${this.$route.query.id}&resultId=${item.resultId}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_submitRowAlignBtnEvent_message_2'));
                    this.list.splice(index,1,{...item, highlightType:"NORMAL"});
                }
            }).finally(()=>{
                this.submitRowAlignBtnLoading = false;
            })
        },
        //删除按钮事件
        deleteBtnEvent(){
            if(Object.keys(this.selectedCol).length>0){
                // this.$refs.alertDialogDom.setShow(true);
                this.deleteConfirmEvent();
            }else{
                this.$message.warning(this.$t('tools.tools_alignTiled_page_method_deleteBtnEvent_message_1'));
            }
        },
        //确认删除
        deleteConfirmEvent(){
            let keys = Object.keys(this.selectedCol);
            if(keys.length < 1){
                this.$message.warning(this.$t('tools.tools_alignTiled_page_method_deleteConfirmEvent_message_1'));
                return;
            }
            let tmpSList = [];
            let tmpTList = [];
            keys.map(key=>{
                let item = this.selectedCol[key];
                if(item.type === 'source'){
                    tmpSList.push(item.scope.row.resultId);
                }else if(item.type === 'target'){
                    tmpTList.push(item.scope.row.resultId);
                }
            });
            let url = toolsApi.deleteBdaAlignResult;
            this.deleteBtnLoading = true;
            this.$ajax.post(url, {
                taskId: this.$route.query.id,
                sources: tmpSList,
                targets: tmpTList,
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_deleteConfirmEvent_message_2'));
                    let lastSelectedCol = this.selectedCol[keys[keys.length - 1]];
                    let _index = lastSelectedCol.scope.$index + 1;
                    let _type = lastSelectedCol.type;
                    if(_type === 'source'){
                        if(_index >= this.sourceList.length){
                            _index = this.sourceList.length - 2;
                        }
                        let sItem = this.sourceList[_index];
                        this.selectedCol = {[`${sItem.resultId}-source`]: {scope:{row: sItem, $index: _index}, type: _type}};
                        this.checkedIds = [`${sItem.resultId}-source`];
                    }else if (_type === 'target') {
                        if(_index >= this.targetList.length){
                            _index = this.targetList.length - 2;
                        }
                        let tItem = this.targetList[_index];
                        this.selectedCol = {[`${tItem.resultId}-target`]: {scope:{row: tItem, $index: _index}, type: _type}};
                        this.checkedIds = [`${tItem.resultId}-target`];
                    }
                    this.fixScrollTop = $('.table-body').scrollTop();
                    this.clearHighlight();
                    this.initPage(false);
                }
            }).finally(()=>{
                this.deleteBtnLoading = false;
            })
        },
        //编辑按钮事件
        editBtnEvent(){
            let keys = Object.keys(this.selectedCol);
            let item = this.selectedCol[keys[0]];
            let row = item.scope.row;
            let type = item.type;
            let index = item.scope.$index;
            let edit = row.edit;
            if(!edit){
                if(type === 'source'){
                    this.sourceList.splice(index, 1, {...this.sourceList[index], edit:true});
                }else{
                    this.targetList.splice(index, 1, {...this.targetList[index], edit:true});
                }
                this.splitBtnDisabled = false;
                setTimeout(() => {
                    $('.table-col-item').find('textarea').focus();
                }, 100);
            }
        },
        //单元格复选框选择事件
        tableColCheckChangeEvent(val,scope,type){
            let row = scope.row;
            let index = scope.$index;
            let id = row.resultId;
            let key = `${id}-${type}`;
            if(val){
                this.$set(this.selectedCol,key,{scope,type});
                this.checkedIds.push(key);
            }else{
                this.$delete(this.selectedCol,key);
                let index = this.checkedIds.indexOf(key);
                this.checkedIds.splice(index,1);
            }
        },
        tableColCheckAllEvent(val,type){
            if(val){
                if(type === 'source'){

                }
            }
        },
        downloadFileEvent(command){
            this.downloadResult(command)
        },
        downloadResult(type){
            this.downloadLoading = true;
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                metadataIdList: [this.$route.query.id], 
                title: this.info.title,
                metadataType: 'TOOLKIT_DA',
                moreOptions: {
                    exportFileType: type,
                    resultPoint: 'OT',
                }
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(()=>{
                this.downloadLoading = false;
            })
        },
        queryMemList(queryString, cb){
            this.dialog.memId = '';
            let url = `${memoryApi.memoryDB}?pageSize=100&pageNumber=1&name=${encodeURI(queryString)}&sourceLang=${this.info.sourceLang}&targetLang=${this.info.targetLang}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let records = res.data.records;
                    let tmpList = [];
                    records.map(item=>{
                        tmpList.push({
                            ...item,
                            value:item.name
                        })
                    })
                    cb(tmpList);
                }else{
                    cb([]);
                }
            })
        },
        dialogMemNameSelectEvent(item){
            // console.log('dialogMemNameSelectEvent:::',item,this.dialog)
            this.dialog.memId = item.memId;
        },
        saveToMemDialogOpenEvent(){
            if(this.userInfo.organizationType === 'ENTERPRISE') {
                this.queryTeamOptions();
            }
        },
        saveToMemEvent(){
            if(!this.dialog.name){
                this.$message.error(this.$t('tools.tools_alignTiled_page_method_saveToMemEvent_message_1'));
                return;
            }
            this.dialog.loading = true;
            let url = toolsApi.copyAlignResultToMemory;
            this.$ajax.post(url, {
                taskId: this.$route.query.id,
                memId: this.dialog.memId || undefined,
                memName: this.dialog.name,
                resultPoint: 'OT',
                teamId: this.dialog.team, 
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_saveToMemEvent_message_2'));
                    this.info.isSaved = true;
                }
            }).finally(()=>{
                this.dialog.loading = false;
                this.dialog.show = false;
            })
        },
        showSubmitAllDialogDom(){
            this.$refs.submitAllDialogDom.setShow(true);
        },
        submitAllBtnEvent(){
            let url = `${toolsApi.confirmRowAlign}?taskId=${this.$route.query.id}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_submitAllBtnEvent_message_1'));
                    this.initPage(false);
                }
            })
        },
        /**初始化表格拖拽功能 */
        initTableDrag(top){
            let self = this;
            if(top!=undefined && top > -1){
                $('.table-body').scrollTop(top);
            }
            let elements = $(".flex-table .table-body .table-col");
            elements.each((i,el)=>{
                Sortable.create(el, {
                    group: {
                        name: "shared",
                    },
                    animation: 150,
                    easing: "cubic-bezier(1, 0, 0, 1)",
                    handle:'.move-icon',
                    swapClass:'align-item-swap',
                    ghostClass:'align-item-ghost',
                    swap: true,
                    onEnd: event => {
                        // console.log('endEvent:::',event);
                        let _sourceList = [...self.sourceList];
                        let _targetList = [...self.targetList];
                        let currParam = event.item.dataset;
                        let swapParam = event.swapItem.dataset;
                        let currData = _sourceList[currParam.index*1];
                        if(currParam.type === 'target'){
                            currData = _targetList[currParam.index*1];
                        }
                        let swapData = _sourceList[swapParam.index*1];
                        if(swapParam.type === 'target'){
                            swapData = _targetList[swapParam.index*1];
                        }
                        let currText =  currData.text;
                        let swapText = swapData.text;

                        if(this.ctrlKeyDown){
                            // console.log('ctrlKeyDown:::',currText, currParam, swapParam, swapText);
                            //合并数据
                            if(swapParam.type === 'source'){
                                _sourceList[swapParam.index*1].text = swapText + currText;
                            }else{
                                _targetList[swapParam.index*1].text = swapText + currText;
                            }
                            this.$nextTick(()=>{
                                if(currParam.type === 'source'){
                                    _sourceList.splice(currParam.index*1, 1);
                                }else{
                                    _targetList.splice(currParam.index*1, 1);
                                }
                            })
                        }else{
                            console.log('not ctrlKeyDown:::');
                            //交换数据
                            if(currParam.type === 'source'){
                                _sourceList[currParam.index*1].text = swapText;
                            }else{
                                _targetList[currParam.index*1].text = swapText;
                            }
                            if(swapParam.type === 'source'){
                                _sourceList[swapParam.index*1].text = currText;
                            }else{
                                _targetList[swapParam.index*1].text = currText;
                            }
                        }
                        //去除空行
                        /* if(!_sourceList[currParam.index*1].text && !_targetList[currParam.index*1].text){
                            _sourceList.splice(currParam.index*1, 1);
                            _targetList.splice(currParam.index*1, 1);
                        }
                        if(!_sourceList[swapParam.index*1].text && !_targetList[swapParam.index*1].text){
                            _sourceList.splice(swapParam.index*1, 1);
                            _targetList.splice(swapParam.index*1, 1);
                        } */

                        let top = $('.table-body').scrollTop();
                        self.sourceList = [];
                        self.targetList = [];
                        self.$nextTick(() => {
                            self.sourceList = _sourceList;
                            self.targetList = _targetList;
                            self.$nextTick(() => {
                                self.initTableDrag(top);
                            });
                        });
 
                        //提交数据到后端
                        let params = {
                            taskId: this.$route.query.id,
                            operationType: this.ctrlKeyDown ? 'merge' : 'exchange',
                            active: {
                                resultId: currData.resultId,
                                // sortNo:currData.sortNo,
                                textType: currParam.type,
                            },
                            passive: {
                                resultId: swapData.resultId,
                                // sortNo:swapData.sortNo,
                                textType: swapParam.type,
                            }
                        };
                        let oldPage = this.pageNumber;
                        let url = toolsApi.submitBdaAlignDrag;
                        this.$ajax.post(url, params).then(res=>{
                            if(res.status === 200){
                                this.clearHighlight();
                                this.pageNumber = oldPage;
                            }
                        })
                    }
                })
            })
        },
        onContextmenu(event){
            $("[class^='Contextmenu_menu']").remove();
            this.$contextmenu({
                items: [
                    {
                        label: this.$t('tools.tools_alignTiled_page_method_onContextmenu_item_label_1'),
                        icon: 'el-icon-plus',
                        disabled: this.addBtnDisabled,
                        onClick: ()=>{
                            this.addBlankLineEvent();
                        }
                    },{
                        label: this.$t('tools.tools_alignTiled_page_method_onContextmenu_item_label_2'),
                        icon: 'icon-basic ib-edit-source',
                        disabled: this.editBtnDisabled,
                        onClick: ()=>{
                            this.editBtnEvent();
                        }
                    },/* {
                        label: '拆分',
                        icon: 'icon-basic ib-split',
                        disabled: this.splitBtnDisabled,
                        onClick: ()=>{
                            this.splitColEvent();
                        }
                    }, */{
                        label: this.$t('tools.tools_alignTiled_page_method_onContextmenu_item_label_3'),
                        icon: 'icon-basic ib-merge',
                        disabled: this.mergeBtnDisabled,
                        onClick: ()=>{
                            this.mergeColEvent();
                        }
                    },/* {
                        label: '对调',
                        icon: 'icon-basic ib-refresh',
                        disabled: this.exchangeBtnDisabled,
                        onClick: ()=>{
                            this.exchangeColEvent();
                        }
                    },{
                        label: '对齐',
                        icon: 'icon-basic ib-data-align',
                        divided: true,
                        disabled: this.alignBtnDisabled,
                        onClick: ()=>{
                            this.aligningColEvent();
                        }
                    }, */{
                        label: this.$t('tools.tools_alignTiled_page_method_onContextmenu_item_label_4'),
                        icon: 'icon-basic ib-trash-o',
                        disabled: this.deleteBtnDisabled,
                        onClick: ()=>{
                            this.deleteBtnEvent();
                        }
                    },

                ],
                event,
                zIndex: 99999,
            });
            return false;
        },
        clearFilterAlignResultBtnEvent(){
            this.cleanUnAlignDialog.message = `${$t('tools.tools_alignTiled_page_method_clearFilterAlignResultBtnEvent_message_1', {name: this.sortOptions[this.searchForm.sort]})}${['SameSource'].indexOf(this.searchForm.sort)>-1? $t('tools.tools_alignTiled_page_method_clearFilterAlignResultBtnEvent_message_2') : ''}${$t('tools.tools_alignTiled_page_method_clearFilterAlignResultBtnEvent_message_3')}`;
            this.$refs.cleanUnAlignDialogDom.setShow(true);
        },
        submitClearFilterAlignResult(){
            let url = toolsApi.clearFilterAlignResult;
            this.$ajax.post(url, {
                taskId: this.$route.query.id,
                filterType: this.searchForm.sort,
                resultPoint: 'OT',
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_submitClearFilterAlignResult_message_1'));
                    this.initPage(false);
                }
            });
        },
        
        /** 查找替换部分 */
        /**
         * 查找逻辑
         */
        initFindReplaceData(){
            if(this.findReplaceDialog.show && this.findReplaceDialog.searchInputValue){
                this.findReplaceMode = true;
            }else{
                this.findReplaceMode = false;
            }
            // console.log('initFindReplaceData：：', this.findReplaceMode)
            if(this.findReplaceMode){
                // debugger;
                let reg = new RegExp(this.findReplaceDialog.searchInputValue, "gi"); //不区分大小写
                if(this.findReplaceDialog.checked){
                    reg = new RegExp(this.findReplaceDialog.searchInputValue, "g"); //区分大小写
                }
                let findSize = 0;
                let pageNumbers = [];
                this.sourceList = this.sourceList.map((item, index)=>{
                    let sHtml = item.text ? item.text.replace(reg, char=>{return `<span class="find-word">${char}</span>`}) : '';
                    let sMatchs = (item.text || '').match(reg) || [];
                    findSize = findSize + sMatchs.length;
                    if(sMatchs.length > 0){
                        let page = this.getPageNumForIndex(index);
                        if(pageNumbers.indexOf(page) < 0){
                            pageNumbers.push(page);
                        }
                    }
                    return {
                        ...item,
                        html: sHtml,
                        edit: false,
                    }
                });
                this.targetList = this.targetList.map((item, index)=>{
                    let tHtml = item.text ? item.text.replace(reg, char=>{return `<span class="find-word">${char}</span>`}) : '';
                    let tMatchs = (item.text || '').match(reg) || [];
                    findSize = findSize + tMatchs.length;
                    if(tMatchs.length > 0){
                        let page = this.getPageNumForIndex(index);
                        if(pageNumbers.indexOf(page) < 0){
                            pageNumbers.push(page);
                        }
                    }
                    return {
                        ...item,
                        html: tHtml,
                        edit: false,
                    }
                });
                this.findSize = findSize;
                this.findPageNumbers = pageNumbers;
                this.handleCurrentChange(this.findPageNumbers.length ? this.findPageNumbers[0] : 1);
                if (this.findSize === 0){
                    this.$message.warning(this.$t('tools.tools_alignTiled_page_method_initFindReplaceData_message_1'));
                }
            }
        },
        /**
         * 根据列表索引计算数据所在的页码
         */
        getPageNumForIndex(index){
            let currPageNumber = 1;
            let pageCount = Math.floor(this.total / this.pageSize) + (this.total % this.pageSize ? 1 : 0);
            for(let i = 1; i <= pageCount; i++){
                if((i-1)*this.pageSize <= index && index < i*this.pageSize){
                    currPageNumber = i;
                    break;
                }
            }
            return currPageNumber;
        },
        closeFindReplaceDialogEvent(){
            this.findReplaceDialog.show = false;
            this.findReplaceDialog.searchInputValue = '';
            this.findReplaceDialog.replaceInputValue = '';
            this.findReplaceDialog.checked = false;
            this.findReplaceMode = false;
            // this.pageNumber = 1;
            this.initPage(false);
        },
        searchInputChangeEvent(value){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                this.pageNumber = 1;
                this.searchForm.sort = '';
                if (value){
                    self.initFindReplaceData();
                }else {
                    this.findReplaceMode = false;
                    self.initPage(false);
                }
            },800);
        },
        getCurrentPageFindSize(){
            let nodes = $('.find-word');
            let cIndex = -1;
            for(let i=0;i<nodes.length; i++){
                if($(nodes[i]).hasClass('active')){
                    cIndex = i;
                    break;
                }
            }
            this.findCurrentPageSize = {
                count: nodes.length,
                current: cIndex + 1,
            }
        },
        prevBtnEvent(){
            let nodes = $('.find-word');
            let currIndex = 0;
            for(let i=0;i<nodes.length; i++){
                if($(nodes[i]).hasClass('active')){
                    currIndex = i;
                    break;
                }
            }
            if(this.findPageNumbers.length === 0){
                this.$message.warning(this.$t('tools.tools_alignTiled_page_method_prevBtnEvent_message_1'));
                return;
            }
            // console.log('prevBtnEvent::',this.pageNumber, this.findPageNumbers, nodes.length, currIndex);
            if(nodes.length === 0){
                if(this.pageNumber <= this.findPageNumbers[0]){
                    this.handleCurrentChange(this.findPageNumbers[this.findPageNumbers.length-1], 'prev');
                }else if(this.pageNumber > this.findPageNumbers[this.findPageNumbers.length-1]){
                    this.handleCurrentChange(this.findPageNumbers[this.findPageNumbers.length-1], 'prev');
                }else{
                    let _list = this.findPageNumbers.reverse();
                    for(let val of _list){
                        if(this.pageNumber > val){
                            this.handleCurrentChange(val, 'prev');
                            break;
                        }
                    }
                }
            }else{
                if(currIndex === 0){
                    //翻页或者提示
                    let firstPageNumber = this.findPageNumbers[0];
                    if(this.pageNumber > firstPageNumber){
                        this.handleCurrentChange(this.findPageNumbers[this.findPageNumbers.indexOf(this.pageNumber) - 1], 'prev');
                    }else{
                       this.$message.warning(this.$t('tools.tools_alignTiled_page_method_prevBtnEvent_message_2')); 
                    }
                }else{
                    $($('.find-word')[currIndex]).removeClass('active');
                    $($('.find-word')[currIndex - 1]).addClass('active');
                    this.$scrollTo($('.find-word.active')[0], 30, {
                        container:$('.table-body')[0],
                        offset: -100,
                    });
                    this.getCurrentPageFindSize();
                }
            }
        },
        
        nextBtnEvent(){
            let nodes = $('.find-word');
            let currIndex = 0;
            for(let i=0;i<nodes.length; i++){
                if($(nodes[i]).hasClass('active')){
                    currIndex = i;
                    break;
                }
            }
            if(this.findPageNumbers.length === 0){
                this.$message.warning(this.$t('tools.tools_alignTiled_page_method_nextBtnEvent_message_1'));
                return;
            }
            if(nodes.length === 0){
                if(this.pageNumber < this.findPageNumbers[0]){
                    this.handleCurrentChange(this.findPageNumbers[0]);
                }else if(this.pageNumber >= this.findPageNumbers[this.findPageNumbers.length-1]){
                    this.handleCurrentChange(this.findPageNumbers[0]);
                }else{
                    for(let val of this.findPageNumbers){
                        if(this.pageNumber < val){
                            this.handleCurrentChange(val);
                            break;
                        }
                    }
                }
            }else{
                if(currIndex === nodes.length - 1){
                    //翻页或者提示
                    let findLastPage = this.findPageNumbers[this.findPageNumbers.length-1];

                    if(this.pageNumber < findLastPage){
                        this.handleCurrentChange(this.findPageNumbers[this.findPageNumbers.indexOf(this.pageNumber) + 1]);
                    }else{
                        this.$message.warning(this.$t('tools.tools_alignTiled_page_method_nextBtnEvent_message_2'));
                    }
                }else{
                    $($('.find-word')[currIndex]).removeClass('active');
                    $($('.find-word')[currIndex + 1]).addClass('active');
                    this.$scrollTo($('.find-word.active')[0], 30, {
                        container:$('.table-body')[0],
                        offset: -100,
                    });
                    this.getCurrentPageFindSize();
                }
            }
        },

        caseSensitiveChangeEvent(val){
            this.pageNumber = 1;
            this.initFindReplaceData();
        },
        replaceBtnEvent(){ 
            let colNode = $('.find-word.active').parents('.table-col');
            let index = colNode.data('index')*1;
            let type = colNode.data('type');
            let colData = this.sourceList[index];
            if(type === 'target'){
                colData = this.targetList[index];
            }
            let colFindNodes = colNode.find('.find-word');
            let num = -1;
            for (let i = 0; i < colFindNodes.length; i++){
                if($(colFindNodes[i]).hasClass('active')){
                    num = i;
                    break;
                }
            }
            if(num === -1){
                this.$message.warning(this.$t('tools.tools_alignTiled_page_method_replaceBtnEvent_message_1'));
                return;
            }
            let charIndex = tools.getCharPostion(this.findReplaceDialog.searchInputValue,colData.text,this.findReplaceDialog.checked)[num];
            if (charIndex === undefined) charIndex = -1;
            if(charIndex === -1){
                return;
            }
            let endIndex = charIndex + this.findReplaceDialog.searchInputValue.length;
            let _text = colData.text.substring(0,charIndex) + this.findReplaceDialog.replaceInputValue + colData.text.substring(endIndex);
            let pUrl = toolsApi.updateBdaAlignResult;
            let postData = {
                taskId: this.$route.query.id,
                resultId: colData.resultId,
                textType: type,
                text: _text,
            };
            this.findReplaceDialog.replaceLoading = true;
            this.$ajax.post(pUrl, postData).then(res=>{
                if(res.status == 200){
                    let findNodes = $('.find-word');
                    let currIndex = -1;
                    for(let i = 0; i < findNodes.length; i++){
                        if($(findNodes[i]).hasClass('active')){
                            currIndex = i;
                            break;
                        }
                    }
                    let _data = colData;
                    let reg = new RegExp(this.findReplaceDialog.searchInputValue, "gi"); //不区分大小写
                    if(this.findReplaceDialog.checked){
                        reg = new RegExp(this.findReplaceDialog.searchInputValue, "g"); //区分大小写
                    }
                    _data.text = _text;
                    _data.html = _text.replace(reg, char=>{return `<span class="find-word">${char}</span>`});
                    if(type === 'source'){
                        this.sourceList.splice(index, 1, _data);
                    }else if(type === 'target'){
                        this.targetList.splice(index, 1, _data);
                    }
                    
                    setTimeout(()=>{
                        $($('.find-word')[currIndex]).addClass('active');
                        this.$scrollTo($('.find-word.active')[0], 30, {
                            container:$('.table-body')[0],
                            offset: -100,
                        });
                        this.getCurrentPageFindSize();
                    },100);
                }
            }).finally(()=>{
                this.findReplaceDialog.replaceLoading = false;
            });

        },
        replaceAllBtnEvent(){
            let self = this;
            let url = toolsApi.replaceBdaAllAlignResult;
            let postData = {
                taskId: this.$route.query.id,
                findWhat: this.findReplaceDialog.searchInputValue,
                replacement: this.findReplaceDialog.replaceInputValue,
                ignoreCase: !this.findReplaceDialog.checked,
                scope: 'ST'
            };
            this.findReplaceDialog.replaceAllLoading = true;
            this.$ajax.post(url, postData).then(res=>{
                if(res.status == 200){
                    let replaceResult = res.data;
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_replaceAllBtnEvent_message_1', {count1: replaceResult.affectedSourceCount, count2: replaceResult.affectedTargetCount, count3: replaceResult.positionCount}));
                    this.initPage(false, 'replaceAll');
                }
            }).finally(()=>{
                this.findReplaceDialog.replaceAllLoading = false;
            });
        },
        switchResultEvent(type, status){
            if(status !== 'S'){
                return false;
            }
            let url = `/tools/align/detail?id=${this.info.taskId}`;
            if(type === 'OT'){
                url = `/tools/align/tiled?id=${this.info.taskId}`;
            }
            location.replace(url);
            
        },
        setPageSize(){
            let url = toolsApi.updatePageSize;
            this.$ajax.post(url, {toolType: 'SA', resultPoint: 'OT', pageSize: this.pageSize, pageNo: this.pageNumber}).then(res=>{
                if(res.status == 200){
                    // this.initPage(false);
                }
            })
        },
        clearHighlight(){
            this.sourceList = this.sourceList.map(item => {
                return {
                    ...item,
                    highlightType: null
                }
            });
            this.targetList = this.targetList.map(item => {
                return {
                    ...item,
                    highlightType: null
                }
            });
        },
        queryTeamOptions() {
            let url = commonApi.queryTeamFilterOptions;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.dialog.teamOptions = res.data.teamFilters;
                    this.dialog.team = this.dialog.teamOptions[0].value;
                }
            });
        },
        saveEditTitleEvent(title) {
            let url = toolsApi.saveAlignEditTitle;
            this.$ajax.post(url, {
                taskId: this.info.taskId,
                title: title,
            }).then(res => {
                if(res.status == 200) {
                    this.$message.success(this.$t('tools.tools_alignTiled_page_method_saveEditTitleEvent_message_1'));
                    this.info.title = title;
                }
            });
        },
    },
    created() {
        $(".el-main .main").css('height', '100%');
        $(".el-main").css({'overflow': 'hidden', 'padding-bottom': '0'});
    },
    destroyed(){
        $(".el-main").removeAttr('style');
        clearInterval(this.querySubtaskTimer);
        this.querySubtaskTimer = null;
    },
    mounted(){
        let self = this;
        this.initPage();
        window.onresize = () =>{
            return (()=>{
                this.$nextTick(()=>{
                    this.tableHeight = 
                        $('body').height() - 20 -
                        $('.page-title-component').height() -
                        $('.page-tools-content').height() - 10 - 42;
                })
            })()
        };
        $(document).keydown(e=>{
            if(e.shiftKey){
                $('.flex-table').addClass('text-unselect');
            }
            if(e.ctrlKey){
                self.ctrlKeyDown = true;
            }
        });
        $(document).keyup(e=>{
            $('.flex-table').removeClass('text-unselect');
            self.ctrlKeyDown = false;
        });

        $(document).click(e=>{
            $("[class^='Contextmenu_menu']").remove();
        });
        
        //快捷键部分

        //撤销
        this.$shortcut.bind('ctrl+z,⌘+z', ()=>{
            this.repealBtnEvent();
        });
        //恢复
        this.$shortcut.bind('ctrl+y,⌘+y', ()=>{
            this.recoverBtnEvent();
        });
        //编辑
        this.$shortcut.bind('ctrl+e,⌘+e', ()=>{
            this.editBtnEvent();
        });
        //插入
        this.$shortcut.bind('ctrl+i,⌘+i', ()=>{
            this.addBlankLineEvent();
        });
        //拆分 在句段编辑保存逻辑中处理 saveEditEvent
        // this.$shortcut.bind('ctrl+enter,⌘+enter', ()=>{
        //     this.splitColEvent();
        // });
        //合并
        this.$shortcut.bind('ctrl+m,⌘+m', ()=>{
            this.mergeColEvent();
        });
        // 删除
        this.$shortcut.bind('ctrl+delete,⌘+backspace', ()=>{
            this.deleteConfirmEvent();
            // this.deleteBtnEvent();
        });
        // 查找替换
        this.$shortcut.bind('ctrl+f,⌘+f', ()=>{
            this.findReplaceDialog.show = true;
        });
    },
    watch:{
        selectedCol:{
            deep:true,
            immediate: true,
            handler(val,oldVal){
                let len = Object.keys(this.selectedCol).length;
                if(len === 1){
                    this.addBtnDisabled = false;
                    this.editBtnDisabled = false;
                }else{
                    this.addBtnDisabled = true;
                    this.editBtnDisabled = true;
                }
                if(len>0){
                    this.deleteBtnDisabled = false;
                }else{
                    this.deleteBtnDisabled = true;
                }
                if(len === 2){
                    this.exchangeBtnDisabled = false;
                }else{
                    this.exchangeBtnDisabled = true;
                }
                if(len > 1){
                    this.mergeBtnDisabled = false;
                }else{
                    this.mergeBtnDisabled = true;
                }
                let hasSource = false; 
                let hasTarget = false;
                Object.keys(this.selectedCol).map(key=>{
                    if(this.selectedCol[key].type === 'source'){
                        hasSource = true;
                    }
                    if(this.selectedCol[key].type === 'target'){
                        hasTarget = true;
                    }
                });
                if(hasSource && hasTarget){
                    this.alignBtnDisabled = false;
                }else{
                    this.alignBtnDisabled = true;
                }
            }
        },
        sourceList(val, oldVal){
            this.total = this.targetList.length > this.sourceList.length ? this.targetList.length : this.sourceList.length;
        },
        targetList(val, oldVal){
            this.total = this.targetList.length > this.sourceList.length ? this.targetList.length : this.sourceList.length;
        }
    }
}
</script>
<style lang="scss" scoped>
.align-detail-page{
    // padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .table-col-icon{
        margin-right: 15px;
        cursor: pointer;
        i{
            font-size: 17px;
            color: #000000;
        }
    }
    .table-col-active {
        background-color: #d2f5e7;
    }
    .more-info{
        font-size: 20px;
    }
    .align-content{
        display:flex;
        margin-bottom: 20px;
        > .left{
            flex:1;
            border: 1px solid #0000001A;
            box-sizing: border-box;
            border-radius: 12px 0 0 12px;
            background-color: #F2F2F2;
            padding: 20px;
            min-height: 88px;
        }
        > .center{
            width: 60px;
            background-color: #000000;
            color: #ffffff;
            font-size: 15px;
            display:flex;
            align-items: center;
            justify-content: center;
            min-height: 88px;
            cursor: pointer;
            &[disabled], &:disabled{
                opacity: .5;
                pointer-events: none;
                cursor: not-allowed;
            }
        }
        > .right{
            flex:1;
            border: 1px solid #0000001A;
            box-sizing: border-box;
            border-radius: 0 12px 12px 0;
            background-color: #F2F2F2;
            padding: 20px;
            min-height: 88px;
        }
        .align-item{
            display: flex;
            margin-bottom: 10px;
            // background-color: #F2F2F2;
            &:last-child{
                margin-bottom: 0;
            }
            > .handle{
                font-size: 24px;
                cursor: move;
                user-select: none;
            }
            > .content{
                font-size: 14px;
                line-height: 20px;
                flex: 1;
                padding: 0 10px;
            }
            > .actions{
                font-size: 20px;
                cursor: pointer;
            }
        }
    }
    .col-content{
        display: flex;
        > .text-content{
            flex: 1;
            font-size: 14px;
            &[disabled], &:disabled{
                opacity: .5;
                pointer-events: none;
                cursor: not-allowed;
            }
        }
        > .actions{
            width: 40px;
            font-size: 20px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            i{
                display: none;
            }
        }
    }
    .table-content{
        flex:1;
    }
    
    .el-table__row{
        &:hover{
            .col-content{
                > .actions{
                    i{
                        display: block;
                    }
                }
            }
        }
    }
    
}
.flex-table > .table-body > .table-row > .table-col{
    padding: 0;
    // min-height: 36px;
}
.table-col-item{
    display: flex;
    padding: 8px 10px;

    // align-items: center;
    // height: 100%;
    > .move-icon{
        font-size: 20px;
        padding-right: 8px;
        cursor: move
    }
    > .flag-icon{
        margin-right: 15px;
        cursor: pointer;
        .star-on{
            color: #FF9900;
        }
        .star-off{
            color: #BFBFBF;
            &:hover{
                color: #000000;
            }
        }
    }
    ::v-deep .find-word{
        background-color: #FFF370;
        &.active{
            background-color: #f3bd53;
        }
    }
    ::v-deep .el-textarea__inner{
        color: #000000;
    }
}
.unconfirm-row{
    background: #FFF2D1;
}
.col-active{
    background-color: #F2F2F2;
}
.table-title{
    font-weight: 600;
    font-size: 15px;
    margin-bottom:15px;
}
.align-item-swap{
    background-color: #d2f5e7;
}
.align-item-ghost{
    background-color: #f3f3f3;
}
.align-row-btn{
    cursor: pointer;
    font-size: 12px;
    color: #fff;
    background-color:#000000; 
    display: inline-block;
    border-radius: 50px;
    padding: 0px 12px;
    &[disabled],&:disabled{
        pointer-events: none;
        opacity: .8;
    }
}
.find-replace-content{
    .form-content{
            margin: 0px 20px;
            > .input-item{
                height: 40px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                display: flex;
                margin-bottom: 10px;
                > .input-label{
                    width: 76px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    color: #000000;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    > span{
                        margin-right: 8px;
                    }
                }
                > .input-edit{
                    flex: 1;
                }
                > .clear-btn{
                    width: 24px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    cursor: pointer;
                    margin-right: 10px;
                    color: #00000066;
                    &:hover{
                        color: #000000;
                    }
                }
                ::v-deep .el-input__inner{
                    border: none;
                }
            }
            .input-check{
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
            }
            .input-btns{
                // margin-bottom: 20px;
                display: flex;
                justify-content: center;
            }
        }
}
</style>
<style lang="scss">
.align-detail-page{
    .el-table td {
        vertical-align: top;
    }
    .el-table .unconfirm-row{
        background: #FFFBF2;
    }
    .col-active{
        background-color: #F2F2F2;
    }
    .el-alert--info.is-dark {
        // margin-bottom: 10px;
        background-color: #f7efe0;
        color: #000000;
        border-radius: 0px;
    }
    .el-alert.is-dark .el-alert__closebtn {
        color: #000000;
        font-size:16px;
        margin-top:-2px;
    }
}
</style>
